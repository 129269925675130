<template>
  <el-card style="width: 100%;margin-bottom: 30px;">
    <div class="card-header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="文章类型" prop="type">
          <el-select v-model="searchForm.type" placeholder="请选择" clearable>
            <el-option label="公司新闻" value="1" />
            <el-option label="行业资讯" value="2" />
            <el-option label="常见问题" value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="search">
          <el-input placeholder="请输入标题查询" v-model="searchForm.search"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button type="primary" @click="dialogVisible = true; isadd = true;">新增资讯</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
  <el-table :data="tableData" style="width: 100%" height="700">
    <el-table-column prop="id" label="文章ID" align="center" />

    <el-table-column prop="image" label="文章主图" align="center">
      <template v-slot="{ row }">
        <img :src="row.image" alt="品牌图片" srcset="" class="avator" />
      </template>
    </el-table-column>
    <el-table-column prop="title" label="文章标题" align="center" />
    <el-table-column prop="post_date" label="时间" align="center" />
    <el-table-column prop="shelf_status" label="上架/下架" align="center">
      <template #default="scope">
        <el-switch v-model="scope.row.shelf_status" active-color="#13ce66" inactive-color="#ff4949"
          @change="switchUp(scope.row)">
        </el-switch>
      </template>
    </el-table-column>
    <el-table-column prop="action" label="操作" fixed="right" width="250">
      <template #default="scope">
        <el-button type="primary" size="small" @click="edit(scope.row); isadd = false">编辑</el-button>
        <el-button type="primary" size="small" @click="delitem(scope.row.id)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination layout="prev, pager, next" :total="total" @current-change="changepage" :current-page="searchForm.page"
    :page-size="15" />
  <el-dialog v-model="dialogVisible" title="新增品牌" @close="reset(formLabelAlignRef)">
    <!-- <img  :src="formLabelAlign.logo" /> -->
    <el-form label-position="left" label-width="auto" :model="formLabelAlign" :rules="Dramarules"
      ref="formLabelAlignRef">
      <el-form-item label="文章主图" prop="image" required>
        <el-upload class="avatar-uploader" :show-file-list="false" :http-request="beforeAvatarUpload" name="image">
          <!-- <img v-if="formLabelAlign.logo.length!==0" :src="formLabelAlign.logo" class="avatar" /> -->
          <img :src="formLabelAlign.image" v-if="formLabelAlign.image" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="文章标题" prop="title" required>
        <el-input v-model="formLabelAlign.title" placeholder="请输入文章标题" clearable />
      </el-form-item>
      <el-form-item label="文章简介" prop="introduction" required>
        <el-input v-model="formLabelAlign.introduction" placeholder="请输入文章简介" clearable />
      </el-form-item>
      <el-form-item label="是否上架" prop="shelf_status" required>
        <el-switch v-model="formLabelAlign.shelf_status" active-color="#13ce66" inactive-color="#ff4949">
        </el-switch>
      </el-form-item>
      <el-form-item label="文章类型" required prop="type">
        <el-select v-model="formLabelAlign.type" placeholder="请选择" clearable>
          <el-option label="公司新闻" value="1" />
          <el-option label="行业资讯" value="2" />
          <el-option label="常见问题" value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="文章日期" prop="post_date" required>
        <el-date-picker v-model="formLabelAlign.post_date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" type="datetime"
          placeholder="请选择日期" />
      </el-form-item>
      <el-form-item label="文章内容" prop="body" required>
        <div>
          <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig"
            mode="default" />
          <Editor style="height: 500px; overflow-y: hidden;" v-model="formLabelAlign.body" :defaultConfig="editorConfig"
            mode="default" @onCreated="handleCreated" />
        </div>
      </el-form-item>


    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="reset(formLabelAlignRef)">取消</el-button>
        <el-button type="primary" v-if="isadd" @click="creatFilm(formLabelAlignRef)">
          确认新增
        </el-button>
        <el-button type="primary" v-else @click="editarTile(formLabelAlignRef)">
          确认编辑
        </el-button>
      </div>
    </template>
  </el-dialog>


</template>

<script setup>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import store from "@/store";
import { ElMessage } from "element-plus";
import { Plus } from '@element-plus/icons-vue'
import { getCurrentInstance, reactive, ref, onBeforeUnmount, shallowRef, onMounted } from 'vue'
import { articles, postArticles, uploadImg, delArticles, editarticles } from '@/api/article.js'
const formLabelAlignRef = ref()
const planRef = ref()
const priceRef = ref()
let isadd = ref(true)
const dialogVisible = ref(false)
const planVisible = ref(false)
const addplanVisible = ref(false)
const priceVisible = ref(false)
const editorRef = shallowRef()
const toolbarConfig = {}
const editorConfig = { placeholder: '请输入内容...', MENU_CONF: {}, }
editorConfig.MENU_CONF['uploadImage'] = {
  server: 'https://kjlmqyapidev.unions88.com/api/upload/image',
  timeout: 5 * 1000,
  fieldName: 'image',
  maxFileSize: 10 * 1024 * 1024, // 10M
  // 自定义增加 http  header
  headers: {
    Authorization:`Bearer ${store.state.user.token}`
  },
  customInsert(res, insertFn) {
    console.log(res)
    insertFn(res.url, null, res.url);
  },
}
const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
}
//剧目ID
let FilmID = ref(0)
//剧目表单
let formLabelAlign = ref({
  body: "",
  image: '',
  type: '',
  title: '',
  post_date: '',
  introduction: ''
})
let searchForm = reactive({
  page: 1,
  search: '',
  type: '1'
})
//文章查询
const onSubmit = () => {
  searchForm.page = 1;
  getData();
}

const getData = () => {
  articles(searchForm)
    .then(res => {
      console.log(res);
      tableData.value = res.data.concat();
      total = res.total;
    })
}
getData();

//文章新增表单规则
const Dramarules = reactive({
  title: [
    { required: true, message: '请输入文章标题', trigger: 'blur' },
  ],
  type: [
    { required: true, message: '请选择文章类型', trigger: 'blur' },
  ],
  image: [
    { required: true, message: '请选择图片', trigger: 'blur' },
  ],
  introduction: [
    { required: true, message: '请输入文章简介', trigger: 'blur' },
  ],
})


let tableData = ref([])
let plantableData = ref([])
let total = ref(0)
let currentPage = ref(1);
//删除函数
const delitem = (id) => {
  delArticles(id)
    .then(res => {
      ElMessage.success('删除成功');
      getData();
    })
}

const switchUp = (row) => {
  let data = {
    id: row.id,
    shelf_status: row.shelf_status
  }
  editarticles(data)
    .then(res => {
      console.log(res)
      ElMessage.success('操作成功')
      getData();
    })
}
//翻页函数
const changepage = (data) => {
  console.log(data);
  searchForm.page = data;
  getData();
}
//新增剧目
const creatFilm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      postArticles(formLabelAlign.value)
        .then(res => {
          console.log(res)
          ElMessage.success('新增成功')
          reset(formEl)
          getData();

        })
    } else {
      console.log('error submit!', fields)
    }
  })

}
const editarTile = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      editarticles(formLabelAlign.value)
        .then(res => {
          console.log(res)
          ElMessage.success('编辑成功')
          reset(formEl)
          getData();

        })
    } else {
      console.log('error submit!', fields)
    }
  })

}
const reset = (formEl) => {
  formLabelAlign.value = {
    body: "",
    image: '',
    type: '',
    title: '',
    post_date: '',
    introduction: ''
  }
  dialogVisible.value = false;
}

//图片上传
const beforeAvatarUpload = (option) => {
  console.log(option);
  let data = new FormData();
  data.append('image', option.file);
  uploadImg(data)
    .then(res => {
      ElMessage.success('上传成功');
      formLabelAlign.value.image = res.url;
      console.log(res)
    })
}

//编辑文章
const edit = (row) => {
  dialogVisible.value = true;
  isadd.value = false
  console.log(row);
  formLabelAlign.value.id = row.id;
  formLabelAlign.value.title = row.title;
  formLabelAlign.value.image = `${row.image}?time=${Math.random() * 100}`
  formLabelAlign.value.introduction = row.introduction;
  formLabelAlign.value.shelf_status = row.shelf_status;
  formLabelAlign.value.post_date = row.post_date;
  formLabelAlign.value.type = `${row.type}`;
  formLabelAlign.value.body = row.body;
  console.log(formLabelAlign)
  dialogVisible.value = true;
}




</script>

<style lang="scss" scoped>
.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}

.avatar {
  width: 400px;
  height: 400px;
}

.threehidden {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all
}

.avator {
  width: 140px;
}
</style>
